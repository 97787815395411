<!-- 考试大类 -->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/.el-pagination__jump {
    margin-left: 0;
    margin-right: 10px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import UserHeader from "../user-header.vue";
  import YzSearch from "@/components/form/yzSearch.vue";
  import EduHandle from "@/components/user/register_user/use/edu-handle.vue";
  import {
    deleteUserEdu,
    handleUserEdu,
    userEduHandleBatch,
  } from "@/api/userInfo_user";
  import {
    userEduPage
  } from "@/api/admin/user";
  import HandleStatus from "@/components/table/handleStatus";
  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      UserHeader,
      YzSearch,
      EduHandle,
      HandleStatus,
    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin",
          },
          {
            text: "用户管理",
            active: true,
          },
        ],
        checked: true,
        // defaultvalue: 3,
        // currentpage: 3,
        tabList: [],
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 1,
        },
        showPromission: false,
        sfType: {
          1: "居民身份证",
          5: "部队离退休证",
          9: "境外永久居住证",
          2: "军官证",
          6: "香港特区护照/身份证明",
          A: "护照",
          3: "士兵证",
          7: "澳门特区护照/身份证明",
          B: "户口薄",
          4: "文职干部证",
          8: "台湾居民来往大陆通行证",
          Z: "其他",
        },
        form: {
          uid: "",
          mm: "",
        },
        search: [],
        handleForm: {},

        isIndeterminate: false,
        checkList: []
      };
    },
    methods: {
      getUserList() {
        userEduPage(this.pageData).then((res) => {
          if (res.status) {
            this.tabList = res.data;
            this.pageData.total = res.total;
          }
        });
      },
      searchClick() { //搜索时先将分页的页码归1
        this.pageData.pageNum = 1; //当前页				
        this.getUserList() //渲染数据的函数
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pageData.pageSize = val;
        this.getUserList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val;
        console.log(`当前页: ${val}`);
        this.getUserList();
      },
      // 删除
      deleteUserEdu(sid, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            deleteUserEdu(sid).then((res) => {
              if (res.status) {
                this.$message({
                  type: "success",
                  message: "数据删除成功!",
                });
              }
              this.getUserList();
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      },
      handle() {
        handleUserEdu(this.handleForm.sid, this.handleForm).then(res => {
          if (res.status) {
            this.$notify({
              title: "成功",
              message: "审核成功",
              type: "success",
            });
            this.$refs.eduHandle.hide()
            this.getUserList()
          }
        })
      },
      checkAll(e) {
        this.checkList = e ? this.tabList.map(item => {
          return item.sid
        }) : []
        this.isIndeterminate = e
      },
      handleBatch(s) {
        this.$confirm(`${s==1?'是否批量审核通过？':'是否批量审核不通过'}`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          userEduHandleBatch(this.checkList.join(","), s).then(res => {
            if (res.status) {
              this.$message({
                type: "success",
                message: "审核成功!",
              });
              this.getUserList();
            }
          })
        }).catch(() => {
        });
      }
    },
    mounted() {
      this.getUserList();
      this.search = [{
        title: "系统审核状态",
        bound: ["xlxtrzzt"],
        type: "select",
        dict: [{
            name: "待审",
            value: "0"
          },
          {
            name: "审核通过",
            value: "1"
          },
          {
            name: "审核未通过",
            value: "2"
          },
        ]
      }, {
        title: "姓名/身份证件号",
        bound: "keyword",
        type: "input",
      }, ];
    },
  };
</script>

<template>
  <Layout>
    <UserHeader :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList">
                <yz-search :data="search" v-model="pageData" @seachList="searchClick"></yz-search>
                <button @click="handleBatch(1)" type="button" class="btn btn-info h30 flexList mr-2">批量审核通过</button>
                <button @click="handleBatch(2)" type="button" class="btn btn-danger h30 flexList mr-2">批量审核不通过</button>
              </div>
            </div>
            <el-checkbox-group v-model="checkList">
              <div class="table-responsive border mt-3">
                <table class="table light-table table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th style="width: 8%">
                        <el-checkbox :indeterminate="isIndeterminate" @change="checkAll"></el-checkbox>
                      </th>
                      <th style="width: 10%">姓名</th>
                      <th style="width: 10%">学历类型</th>
                      <th>毕业学校</th>
<!--                      <th style="width: 8%;">修改人</th>-->
<!--                      <th style="width: 6%;">修改时间</th>-->
                      <th style="width: 10%">学历证明</th>
                      <th style="width: 10%">提交时间</th>
<!--                      <th>获得国家地区</th>-->
<!--                      <th style="width: 8%">系统审核说明</th>-->
                      <th style="width: 10%">系统审核状态</th>
                      <th style="width: 10%">审核状态</th>
                      <th style="width: 5%">删除</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(obj, index) in tabList" :key="index">
                      <td>
                        <el-checkbox :label="obj.sid">{{ index + 1 +(pageData.pageNum-1)*pageData.pageSize  }}</el-checkbox>
                      </td>
                      <td><a href="javascript:;" @click="$refs.eduHandle.show(obj.sfzjhEnc, obj.sid)"
                          class="blue-font">{{ obj.xm }}</a></td>
                      <td>
                        {{obj.xlmc}}
                      </td>
                      <td>{{ obj.byyxxhdw }}</td>
<!--                      <td>{{obj.modifyUser}}</td>-->
<!--                      <td>{{timeSlice(obj.modifyTime)}}</td>-->
                      <td>
                        <a v-if="obj.xlzm" href="Javascript:;" @click="$yzImgDialog().show(getFileUrl(obj.xlzm))"
                           class="blue-font">查看证明</a>
                      </td>
                      <td>{{ timeSlice(obj.createTime) }}</td>
<!--                      <td>{{ obj.gjdqmc }}</td>-->
<!--                      <td>{{ obj.xlxtrzsm }}</td>-->
                      <td>
                        <HandleStatus :shzt="obj.xlxtrzzt"></HandleStatus>
                      </td>
                      <td title="点击审核">
                        <div @click="$refs.eduHandle.show(obj.sfzjhEnc, obj.sid)">
                          <HandleStatus :shzt="obj.xlshzt"></HandleStatus>
                        </div>
                      </td>
<!--                      <td class="tab-icon">-->
<!--                        <button type="button" @click="$refs.eduHandle.show(obj.sfzjhEnc, obj.sid)"-->
<!--                          class="btn btn-info h30 flexList mr-2">-->
<!--                          审核-->
<!--                        </button>-->
<!--                      </td>-->
                      <td>
                        <i
                            v-if="(obj.xlshzt?obj.xlshzt:obj.xlxtrzzt)!= 1"
                            style="cursor:pointer;"
                            @click="deleteUserEdu(obj.sid,obj.xm)"
                            class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                        ></i>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </el-checkbox-group>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EduHandle ref="eduHandle" :bound="['xlshzt', 'xlshbz']" v-model="handleForm" @change="handle"></EduHandle>
  </Layout>
</template>

<template>
  <b-modal
      v-model="showUser"
      centered
      title="考生学历审核"
      size="xl"
      title-class="font-18"
      hide-footer
  >
    <base-info :sfzjh="sfzjh"></base-info>
    <EduInfoForm :sid="sid"></EduInfoForm>
    <div class="flexList check-distri mt-2 p-2">
      <label class="mb-0" style="width: 10%">审核状态：
      </label>
      <div class="w-95 flexList" id="xlshzt">
        {{(edu.xlshzt?edu.xlshzt:edu.xlxtrzzt)==1?'通过':(edu['xlshzt']==2?'未通过':'待审')}}</div>
    </div>
    <div class="flexList check-distri mb-3 p-2">
      <label class="mb-0" style="width: 10%">审核意见：</label>
      <div class="w-95 flexList">
        <textarea id="xlshbz" v-model="edu[bound[1]]" class="w-100 form-control" rows="3"></textarea>
      </div>
    </div>
    <div class="mt-3 text-center">
      <button
          type="button"
          class="btn btn-info h30 w-md mr-3"
          @click="handle(1)"
      >
        通过
      </button>
      <button
          type="button"
          class="btn btn-danger h30 w-md mr-3"
          @click="handle(2)"
      >
        不通过
      </button>
      <button
          type="button"
          class="btn btn-secondary h30 w-md"
          @click="showUser = false"
      >
        取消
      </button>
    </div>
  </b-modal>
</template>

<script>
import {getUserEduDetails} from "@/api/userInfo_user.js";
import BaseInfo from "../base-info.vue";
import EduInfoForm from "../edu-info-form.vue";
export default {
  name: "user-show.vue",
  components: {
    BaseInfo,
    EduInfoForm,
  },
  props:{
    title: String,
    bound: Array,
  },
  model:{
    event: "input",
    data: "form"
  },
  data() {
    return {
      showUser: false,
      sfzjh: "",
      sid: "",
      edu: {}
    };
  },
  methods: {
    handle(status){
      let yj = this.form[this.bound[1]]
      this.form = {}
      this.form[this.bound[0]] = status
      this.form[this.bound[1]] = yj
      this.form['sfzjh'] = this.sfzjh
      this.form['sid'] = this.sid
      this.$emit("input", this.form)
      this.$emit("change", this.form)
    },
    show(sfzjh, sid) {
      this.sfzjh = sfzjh;
      this.sid = sid;
      this.showUser = true;
      getUserEduDetails(this.sid).then((res) => {
        if (res.status) {
          this.edu = res.data
          this.form = res.data
        }
      })
    },
    hide(){
      this.showUser = false;
    }
  },
};
</script>

<style scoped></style>

<template>
  <div v-if="form">
    <div class="mt-2 mb-2">
      <i class="iconfont icon-ze-user-circle-o mr-2"></i>提交学历学位信息
    </div>
    <yzShowTable :form="form" :bounds="bounds"></yzShowTable>
  </div>
</template>

<script>
import {getUserEduDetails} from "@/api/userInfo_user.js";
import yzShowTable from "@/components/form/yzShowTable";
export default {
  props: {
    sid: String,
  },
  components:{
    yzShowTable,
  },
  data() {
    return {
      form: {},
      bounds: [
        {label: "学历类型", bound: "xlmc", width: "50"},
        {label: "起止时间", bound: ["rxny", "jsxyny"], type: "join", join: "-", width: "50"},
        {label: "毕业学校", bound: "byyxxhdw", width: "50"},
        {label: "毕业专业", bound: "sxzymc", width: "50"},
        {label: "授予国家或地区", bound: "gjdqmc", width: "50"},
        {label: "毕业正式编号", bound: "xlzsh", width: "50"},
        // {label: "学位授予情况", bound: "xwxtrzzt", type:'shzt', width: "50"},
        // {label: "学历审核状态", bound: "xlshzt", type:'shzt', width: "50"},
        {label: "学位证书编号", bound: "xwzsh", width: "100"},
        // {label: "学位认证状态", bound: "xwshzt", type:'shzt',  width: "50"},
        {label: "学历证明附件", bound: "xlzm", type:'link',  width: "100"},
      ]
    };
  },
  mounted() {
    getUserEduDetails(this.sid).then((res) => {
      if (res.status) {
        this.form = res.data;
      }
    });
  },
};
</script>

<style></style>
